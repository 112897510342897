<template>
  <div class="container">
    <BaseTitle v-if="title" class="grid-title">{{ title }}</BaseTitle>

    <InfiniteLoading
      v-slot="{ allData: brochures }"
      ref="infiniteLoadingWrapper"
      :data="data?.brochures"
      :has-more-to-load="hasMoreToLoad"
      :pixel-offset="100"
      class="brochures-grid"
      @load-more="loadMore"
    >
      <template v-for="(brochure, index) in brochures" :key="brochure.id">
        <BrochureItem
          ref="brochureItem"
          :brochure="brochure"
          class="brochure-item"
          :impression-source="impressionSource"
        />

        <!-- Google in feed ad every 6th brochure -->
        <AdvertisementGoogleInFeed
          v-if="(index + 1) % 6 === 0"
          :ad-index="(index + 1) / 6 - 1"
          :display-at="InFeedAdDisplayAt.BrochuresGrid"
          class="in-feed"
        />
      </template>
      <BaseText v-if="brochures.length === 0">
        {{ $t("new.no-folders") }}
      </BaseText>
    </InfiniteLoading>
    <CenteredContent v-if="showLoadMore && hasMoreToLoad" class="mt-3 mb-3">
      <BaseButton @click="loadMore(true)">{{ $t("new.load-more") }}</BaseButton>
    </CenteredContent>
  </div>
</template>

<script setup lang="ts">
import { useBrochuresQuery } from "~/graphql/generated";
import type {
  BrochureImpressionSource,
  BrochuresInput,
  PaginationInput,
} from "~/graphql/generated";
import { InFeedAdDisplayAt } from "~/types";

const props = defineProps<{
  title?: string;
  input: BrochuresInput;
  showLoadMore?: boolean;
  pagination: PaginationInput;
  impressionSource: BrochureImpressionSource;
  infiniteLoading?: boolean;
  /** If true, we'll show a row even if the items don't fill an entire row. */
  alwaysShowRow?: boolean;
}>();

// Get the users location (if set)
const location = useStatefulCookie<{ lat: number; long: number } | null>(
  "location",
);

const pagination = toReactive(props.pagination);

// Mark this as reactive, so the query will re-run when the value changes
const variables = computed(() => {
  return {
    brochuresInput: {
      ...props.input,
      ...(location.value &&
        !props.input.citySlug && {
          point: {
            latitude: location.value.lat,
            longitude: location.value.long,
          },
        }),
    },
    pagination,
    limitPlusOffset: pagination.limit + pagination.offset,
  };
});

const { data } = await useBrochuresQuery({
  variables,
});

const loadMore = (force?: boolean) => {
  if ((!props.infiniteLoading && !force) || !data?.value?.brochures) return;
  pagination.offset += data?.value?.brochures.length;
};

const hasMoreToLoad = computed(() => {
  if (!data?.value?.brochures) return false;
  return data?.value?.brochures?.length >= pagination.limit;
});

// Watch the input and reset the infinite loading
const infiniteLoadingWrapper = useTemplateRef("infiniteLoadingWrapper");

// In case of "infinite loading" we want to make sure that we show full rows of items.
if (!props.infiniteLoading && !props.alwaysShowRow) {
  //@ts-expect-error - generic components are having a (type) issue
  useFullRowsOnly(infiniteLoadingWrapper, ".brochure-item, .in-feed");
}

watch(
  () => props.input,
  (newValue, oldValue) => {
    // Prevent the infinite loading from resetting when the input is the same
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;

    infiniteLoadingWrapper.value?.resetData();
    pagination.offset = 0;
  },
);
</script>

<style lang="scss" scoped>
.brochures-grid {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-title::first-letter {
  text-transform: capitalize;
}
</style>
